$(function () {
    $('.owl-question-example').owlCarousel({
        loop:true,
        margin:10,
        items: 1,
        autoplay:true,
        autoHeight:true
    });
    
    $('[data-toggle="tooltip"]').tooltip();
    
    $('.mobile-nav').on('click', function (e) {
        $('.overlay').toggle();
    })
    
    $('.overlay__close').on('click', function () {
        $(this).closest('.overlay').toggle();
    })
})